// extracted by mini-css-extract-plugin
export var checked = "List-module--checked--c830f";
export var disc = "List-module--disc--ad043";
export var fadeInAnimation = "List-module--fadeInAnimation--96b73";
export var item = "List-module--item--a8a13";
export var itemWrapper = "List-module--itemWrapper--01a0b";
export var lg = "List-module--lg--350fe";
export var list = "List-module--list--c4884";
export var md = "List-module--md--1f9d0";
export var mdSame = "List-module--mdSame--e509f";
export var mdV2 = "List-module--mdV2--902bb";
export var number = "List-module--number--92769";
export var sm = "List-module--sm--b5ebe";
export var smallMarginChecked = "List-module--smallMarginChecked--4350a";
export var subItem = "List-module--subItem--e0960";
export var xsm = "List-module--xsm--8f616";